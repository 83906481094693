<template>
    <el-menu style="float: right; font-size: 16px;" active-text-color="#FE9800" :default-active="activeIndex" :router="true" mode="horizontal">
        <el-menu-item index="/">首页</el-menu-item>
        <el-submenu index="2">
            <template slot="title">热江工具</template>
            <el-menu-item index="/patch">韩服更新补丁</el-menu-item>
            <el-menu-item index="/searchItem">韩服乱码查询</el-menu-item>
<!--            <el-menu-item index="/sinization">韩服汉化机</el-menu-item>-->
<!--            <el-menu-item index="/rxClientInfo">韩服登录器</el-menu-item>-->
        </el-submenu>
        <el-menu-item index="/about">关于</el-menu-item>
    </el-menu>
</template>

<script>
    export default {
        name: "NavBar",
        data() {
            return {
                activeIndex: "1"
            }
        },
        methods: {
            /*handleSelect(key, keyPath) {
                console.log(key, keyPath);
            }*/
        }
    }
</script>

<style lang="scss" scoped>

    ::v-deep .el-submenu__title {
        font-size: 16px !important;
    }
    .el-dropdown-menu__item, .el-menu-item {
        font-size: 16px;
    }
</style>